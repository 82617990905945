<i18n src="@/i18n/template/components/MyDateRange.json"></i18n>
<template>
  <span>
    <range-date-picker
      key="start"
      :value="startDate"
      @input="updateStartDate($event)"
      :endTime="endDate"
      :clearable="clearable !== undefined ? clearable : true"
      :placeholder="$t('startTime')"
      :type="datetime ? 'datetime' : 'date'"
      :value-format="datetime ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy'"
      default-time="00:00:00"
      :format="datetime ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy'"
      style="min-width: 100px"
      :intervalDays="intervalDays || 0"
      :disabled="disabled"
      :minDate="minDate"
      :maxDate="maxDate"
    >
    </range-date-picker>
    <span> - </span>
    <range-date-picker
      key="end"
      :value="endDate"
      @input="updateEndDate($event)"
      :startTime="startDate"
      :clearable="clearable !== undefined ? clearable : true"
      :placeholder="$t('endTime')"
      :type="datetime ? 'datetime' : 'date'"
      :value-format="datetime ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy'"
      :format="datetime ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy'"
      default-time="23:59:59"
      style="min-width: 100px"
      :intervalDays="intervalDays || 0"
      :disabled="disabled"
      :minDate="minDate"
      :maxDate="maxDate"
    >
    </range-date-picker>
  </span>
</template>

<script>
export default {
  props: {
    startDate: String,
    endDate: String,
    datetime: Boolean,
    placeholder: String,
    disabled: Boolean,
    clearable: Boolean,
    intervalDays: Number, // 相隔天数
    minDate: String,
    maxDate: String
  },
  methods: {
    updateStartDate(value) {
      this.$emit('update:startDate', value) // 发出更新事件
    },
    updateEndDate(value) {
      this.$emit('update:endDate', value) // 发出更新事件
    }
  },
  data() {
    return {}
  }
}
</script>
