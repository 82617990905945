<i18n src="@/i18n/template/components/FileUploader.json"></i18n>
<template>
  <div class="o-file-uploader">
    <div class="file-button" :style="disabled && { background: '#909399' }">
      <template v-if="!buttonText">
        <i class="el-icon-plus"></i>
        <span>{{ $t('uploadFile') }}</span>
      </template>
      <span v-else>{{ buttonText }}</span>
      <input
        type="file"
        :accept="acceptValue"
        @change="onInputFileChange"
        :disabled="disabled"
        :multiple="multiple"
      />
    </div>
    <div class="file-name" v-if="filename">
      <span>{{ $t('File') }}: {{ filename }}</span>
      <!-- <el-button type="text" icon="el-icon-delete"></el-button> -->
    </div>
    <template v-if="filelist">
      <div
        class="file-name"
        v-for="(item, index) in filelist"
        :key="item.filename + index"
      >
        <span>{{ item.filename }}</span>
        <el-button
          type="text"
          icon="el-icon-delete"
          @click="onRemove(index)"
        ></el-button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'file-uploader',
  props: [
    'accept',
    'source',
    'filename',
    'button-text',
    'filelist',
    'multiple',
    'disabled',
    'maxSize',
    'custId', // 附件上传需要传
    // 限制选择文件类型 video / image
    'fileType',
    // 当前选择的视频时长
    'videoDuration',
    // 限制视频时长 秒
    'videoMaxDuration'
  ],
  computed: {
    acceptValue() {
      if (this.accept) {
        return this.accept
      }
      if (this.fileType === 'video') {
        return 'video/mp4,video/quicktime,video/x-msvideo,video/mpeg,video/x-ms-wmv,video/x-flv,video/mkv,video/rmvb'
      }
      if (this.fileType === 'image') {
        return 'image/jpeg,image/png,image/gif'
      }
      return ''
    }
  },
  data() {
    return {}
  },
  methods: {
    async onInputFileChange(event, index) {
      let file = event.target.files[0]
      if (this.fileType === 'video' && this.videoMaxDuration) {
        let continueProcess = await this.checkVideoDuration(file)
        if (continueProcess !== true) {
          return
        }
      }
      let maxSize = this.maxSize
      if (maxSize && file.size / 1000 > maxSize) {
        let maxSizeString = this.maxSize + 'KB'
        if (maxSize >= 1024) {
          maxSizeString = (maxSize / 1024).toFixed(1) + 'MB'
        }
        this.$message.error(this.$t('UploadImageSizeStr') + ' ' + maxSizeString)
        event.target.value = ''
        return
      }
      let loading = this.$loading()
      let res = await this.$fileApi.upload({
        file: file,
        source: this.source,
        custId: this.custId
      })
      loading.close()
      if (res.code === '0') {
        console.log('res', res)
        this.$emit('done', res, file)
        this.$emit('update:fileData', res)
      }
      event.target.value = ''
    },
    checkVideoDuration(file) {
      return new Promise(resolve => {
        const video = document.createElement('video')
        video.preload = 'metadata'
        video.onloadedmetadata = () => {
          // 输出视频时长
          console.log(video.duration + 's')
          if (video.duration <= this.videoMaxDuration) {
            this.$emit('update:videoDuration', video.duration)
            resolve(true)
          } else {
            let minute = Math.floor(this.videoMaxDuration / 60)
            let second = this.videoMaxDuration % 60
            this.$message.error(
              `Video duration can not exceed: ${minute}m:${second}s`
            )
            resolve(false)
          }
        }
        video.onerror = () => {
          this.$message.error('Load video fail')
          resolve(false)
        }
        // 设置视频源为用户选择的文件
        video.src = URL.createObjectURL(file)
      })
    },
    async onRemove(index) {
      this.$emit('remove', index)
    }
  }
}
</script>

<style lang="scss">
.o-file-uploader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .file-button {
    padding: 2px 12px;
    cursor: pointer;
    background: $--color-primary;
    color: #fff;
    line-height: 24px;
    font-size: 14px;
    border-radius: 3px;
    position: relative;
    overflow: hidden;
    i {
      margin-right: 4px;
    }
    &:hover {
      opacity: 0.8;
    }
    input[type='file'] {
      position: absolute;
      top: 0;
      left: -100%;
      right: -100%;
      bottom: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
  .file-name {
    margin-top: 5px;
    color: #333;
    font-size: 14px;
    .el-button {
      margin-left: 10px;
      color: #888;
    }
  }
}
</style>
