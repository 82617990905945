<template>
  <div class="my-tabs">
    <div class="my-tabs-nav-wrap" ref="navWrap">
      <div
        :class="[
          'my-tabs-tab',
          {
            active: activeKey === item.name,
            hide: item.hide
          }
        ]"
        v-for="(item, index) in navList"
        :key="index"
        @click="handleTabChange(item)"
        ref="navItem"
      >
        {{ item.label }}
      </div>
      <el-popover
        v-model="popoverVisible"
        placement="bottom"
        width="500"
        trigger="hover"
        v-if="showPopoverButton"
      >
        <div class="my-popover-tabs">
          <template v-for="(item, index) in navList">
            <div
              :key="index"
              v-if="item.hide"
              :class="[
                'my-popover-tabs-item',
                { active: activeKey === item.name }
              ]"
              @click="handleMoreChange(item)"
            >
              {{ item.label }}
            </div>
          </template>
        </div>
        <span slot="reference" class="popover-tabs-reference">
          <i class="el-icon-s-grid"></i>
        </span>
      </el-popover>
    </div>
    <div class="my-tabs-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MyTabs',
  props: {
    value: {
      type: [String, Number]
    }
  },
  data() {
    return {
      navList: [],
      activeKey: this.value,
      popoverVisible: false
    }
  },
  computed: {
    showPopoverButton() {
      return this.navList.some(item => item.hide)
    }
  },
  methods: {
    getTabs() {
      return this.$children.filter(item => item.$options.name === 'MyTabPane')
    },
    initTabs() {
      this.updateNav()
      this.updateStatus()
    },
    updateNav() {
      this.navList = []
      this.getTabs().forEach((pane, index) => {
        this.navList.push({
          label: pane.label,
          name: pane.name || index,
          hide: false
        })
        if (index === 0 && !this.activeKey) {
          this.activeKey = pane.name
        }
      })
      this.$nextTick(() => {
        this.navItemWidthUpdated()
      })
    },
    navItemWidthUpdated() {
      let navWrap = this.$refs.navWrap
      if (!navWrap) return
      let navItemWidth = (navWrap.clientWidth || 0) - 35
      if (navItemWidth <= 0) {
        this.waitingNavWrapLayout()
        return
      }
      let width = 0
      for (let i = 0; i < this.navList.length; i++) {
        let item = this.navList[i]
        let el = this.$refs.navItem[i]
        if (el) {
          width += el.clientWidth || 0
          item.hide = width > navItemWidth
        }
      }
      if (this.timer) {
        clearTimeout(this.timer)
      }
    },
    waitingNavWrapLayout() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        let navWrap = this.$refs.navWrap
        if (!navWrap) return
        let navItemWidth = navWrap.clientWidth || 0
        if (navItemWidth <= 0) {
          this.waitingNavWrapLayout()
          return
        }
        this.navItemWidthUpdated()
      }, 500)
    },
    updateStatus() {
      const tabs = this.getTabs()
      tabs.forEach(tab => (tab.show = tab.name === this.activeKey))
    },
    handleTabChange(item) {
      if (item.hide) {
        return
      }
      this.popoverVisible = false
      this.$emit('change', item.name, item)
      this.$emit('input', item.name)
      this.activeKey = item.name
    },
    handleMoreChange(item) {
      this.popoverVisible = false
      this.$emit('change', item.name, item)
      this.$emit('input', item.name)
      this.activeKey = item.name
    }
  },
  mounted() {
    this.initTabs()
    window.addEventListener('resize', this.navItemWidthUpdated)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.navItemWidthUpdated)
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  watch: {
    value(val) {
      this.activeKey = val
    },
    activeKey(val) {
      this.updateStatus()
    }
  }
}
</script>
<style lang="scss">
.my-tabs {
  .my-tabs-nav-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    border: 1px solid #dcdee2;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .my-tabs-tab {
    flex-shrink: 0;
    flex-grow: 0;
    border-right: 1px solid #dcdee2;
    padding: 8px 10px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    &.active {
      border-bottom: none;
      color: $--color-primary;
    }
    &.hide {
      opacity: 0;
      cursor: default;
    }
  }
  .popover-tabs-reference {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      font-size: 20px;
      color: #444;
    }
  }
}
.my-popover-tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .my-popover-tabs-item {
    width: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 30px;
    color: #333;
    &:hover {
      background-color: #f2f2f2;
    }
    &.active {
      color: $--color-primary;
    }
  }
}
</style>
