import '@/i18n/template/util/util.js'
import { NeverExpiredDate, NeverExpiredTime } from '@/constant'
import i18n from '@/i18n'
import dayjs from 'dayjs'

export const formatTimeTemplate = 'DD/MM/YYYY HH:mm:ss'
export const formatDateTemplate = 'DD/MM/YYYY'
export const formatMonthTemplate = 'MM/YYYY'
export const cbpDateTemplate = 'YYYYMMDD'
export const cbpMonthTemplate = 'YYYYMM'

// 暂存原始的 format 方法
const dayjsFormat = dayjs.prototype.format
// format添加默认格式化值
dayjs.prototype.format = function (template = formatTimeTemplate) {
  return dayjsFormat.call(this, template)
}
// format添加默认格式化值
dayjs.prototype.formatDate = function (template = formatDateTemplate) {
  return dayjsFormat.call(this, template)
}
// format添加默认格式化值
dayjs.prototype.formatMonth = function (template = formatMonthTemplate) {
  return dayjsFormat.call(this, template)
}

// new dayjs by time
dayjs.from = function (str, template = formatTimeTemplate) {
  if (str) {
    if (str.length === 10) {
      return dayjs(str + '00:00:00', template)
    }
    return dayjs(str, template)
  }
  return dayjs()
}

export function cbpDate(date, separate = '/') {
  if (!date) return '' // Return specific message for empty input
  if (typeof date === 'number') {
    date = date + ''
  }
  if (typeof date !== 'string' || date.trim() === '') {
    return '' // 处理空字符串和无效字符串
  }
  if (date === NeverExpiredDate || date === NeverExpiredTime) {
    return i18n.t('NeverExpire')
  }
  // 处理 YYYYMM 或 YYYY-MM 格式
  const yearMonthMatch = date.match(/^(?:(\d{4})(?:-?)(\d{2}))$/)
  if (yearMonthMatch) {
    return `${yearMonthMatch[2]}${separate}${yearMonthMatch[1]}` // 返回 MM/YYYY 格式
  }
  const parsedDate = dayjs(date, [
    'YYYYMMDDHHmmss',
    'YYYY-MM-DD',
    'DD-MM-YYYY',
    'YYYY-MM-DD HH:mm:ss',
    'YYYYMMDD',
    'DDMMYYYY'
  ])
  if (!parsedDate.isValid()) {
    return '' // 处理不符合长度的情况
  }
  // 检查是否包含时间部分
  if (date.includes(':') || date.length === 14) {
    return parsedDate.format(`DD${separate}MM${separate}YYYY`)
    // 获取当前时区的偏移（分钟）
    const localOffset = new Date().getTimezoneOffset() // 本地时区偏移（分钟）
    const myanmarOffset = -390 // 缅甸时区偏移（UTC+6:30），转换为分钟
    // 计算时间差
    const offsetDifference = localOffset - myanmarOffset // 计算本地时区与缅甸时区的差值（分钟）
    // 将缅甸时间转换为本地时间
    const localTime = parsedDate.add(offsetDifference, 'minute')
    // 返回格式化后的本地时间
    return localTime.format(`DD${separate}MM${separate}YYYY`)
  }
  return parsedDate.format(`DD${separate}MM${separate}YYYY`) // 只返回日期
}

export function old_cbpDate(date, separate = '/') {
  if (!date) return '' // Return specific message for empty input
  if (typeof date === 'number') {
    date = date + ''
  }
  if (typeof date !== 'string' || date.trim() === '') {
    return '' // 处理空字符串和无效字符串
  }
  if (date === NeverExpiredDate || date === NeverExpiredTime) {
    return i18n.t('NeverExpire')
  }

  // 处理 YYYYMM 或 YYYY-MM 格式
  const yearMonthMatch = date.match(/^(?:(\d{4})(?:-?)(\d{2}))$/)
  if (yearMonthMatch) {
    return `${yearMonthMatch[2]}${separate}${yearMonthMatch[1]}` // 返回 MM/YYYY 格式
  }
  const parsedDate = dayjs(date, [
    'YYYYMMDDHHmmss',
    'YYYY-MM-DD',
    'DD-MM-YYYY',
    'YYYY-MM-DD HH:mm:ss',
    'YYYYMMDD',
    'DDMMYYYY'
  ])
  if (!parsedDate.isValid()) {
    return '' // 处理不符合长度的情况
  }
  return parsedDate.format(`DD${separate}MM${separate}YYYY`) // 只返回日期
}
// convert 120000 =>>> 12:00:00
export function cbpTime(t, separate = ':') {
  if (!t) return '' // 检查输入是否为空
  // 尝试解析日期
  if (t.length == 6) {
    let h = t.substring(0, 2)
    let m = t.substring(2, 4)
    let s = t.substring(4, 6)
    return `${h}${separate}${m}${separate}${s}`
  }
  const parsedDate = dayjs(t)
  // 检查解析后的日期是否有效
  if (!parsedDate.isValid()) {
    return '' // 如果无效，返回空字符串
  }
  return parsedDate.format(`HH${separate}mm${separate}ss`)
  // 获取当前时区的偏移（分钟）
  const localOffset = new Date().getTimezoneOffset() // 本地时区偏移（分钟）
  const myanmarOffset = -390 // 缅甸时区偏移（UTC+6:30），转换为分钟
  // 计算时间差
  const offsetDifference = localOffset - myanmarOffset // 计算本地时区与缅甸时区的差值（分钟）
  // 将缅甸时间转换为本地时间
  const localTime = parsedDate.add(offsetDifference, 'minute')
  // 返回格式化后的本地时间
  return localTime.format(`HH${separate}mm${separate}ss`)
}
// convert 2021-12-01 12:00:00 或者 2021/12/01 12:00:00 或者 01-12-2021 12:00:00 或者 01/12/2021 12:00:00 >> 20211201120000 或者 12:00:00 =>>> 120000 或者 12:00 =>>> 1200
export function toCBPTime(dateTime) {
  if (!dateTime) return ''
  if (typeof dateTime !== 'number') {
    dateTime = dateTime + ''
  }
  if (typeof dateTime !== 'string' || dateTime.trim() === '') {
    return ''
  }
  // 使用正则表达式提取日期和时间部分
  const match = dateTime.match(
    /^(?:(\d{4})[-\/](\d{2})[-\/](\d{2})|(\d{2})[-\/](\d{2})[-\/](\d{4})|(\d{2})[-\/](\d{4})|(\d{4})[-\/](\d{2}))?(?: (\d{2}:\d{2}:\d{2}|(\d{2}:\d{2})))?$/
  )

  if (!match) {
    // 检查是否为 HH:mm 格式
    const timeMatch = dateTime.match(/^(\d{2}):(\d{2})$/)
    if (timeMatch) {
      return `${timeMatch[1]}${timeMatch[2]}` // 返回 HHmm 格式
    }
    return dateTime // 如果格式不匹配，返回原始字符串
  }

  let year, month, day, time

  // 根据匹配的组来确定日期部分
  if (match[1]) {
    // yyyy-MM-dd 或 yyyy/MM/dd 格式
    year = match[1]
    month = match[2]
    day = match[3]
  } else if (match[4]) {
    // dd-MM-yyyy 或 dd/MM/yyyy 格式
    day = match[4]
    month = match[5]
    year = match[6]
  } else if (match[7]) {
    // MM/YYYY 格式
    month = match[7]
    year = match[8]
    return `${year}${month}` // 直接返回 YYYYMM 格式
  } else if (match[9]) {
    // YYYY/MM 格式
    year = match[9]
    month = match[10]
    return `${year}${month}` // 直接返回 YYYYMM 格式
  }
  // 处理时间部分
  time = match[11] ? match[11].replace(/:/g, '') : '' // 如果有时间部分，则去掉冒号

  // 返回转换后的字符串
  return `${year}${month}${day}${time}`
}
// convert 2019-01-01 =>>> 20190101
export const toCBPDate = toCBPTime

// convert 01/2025 =>>> 202501
export function toCBPMonth(date) {
  return dayjs(date, formatMonthTemplate).format(cbpMonthTemplate)
}

export function toBizTime(time) {
  let t = dayjs(time, [
    'DD/MM/YYYY HH:mm:ss',
    'YYYY/MM/DD HH:mm:ss',
    'YYYY-MM-DD HH:mm:ss'
  ])
  if (t.isValid()) {
    return t.format('YYYY-MM-DD HH:mm:ss')
  }
  return ''
}

export function toBizDate(date) {
  let t = dayjs(date, ['DD/MM/YYYY', 'YYYY/MM/DD', 'YYYY-MM-DD'])
  if (t.isValid()) {
    return t.format('YYYY-MM-DD')
  }
  return ''
}

export function random(length) {
  var str = Math.random().toString(36).substr(2)
  if (str.length >= length) {
    return str.substr(0, length)
  }
  str += random(length - str.length)
  return str
}

export function getFileType(filename = '') {
  let fileType = 'other'
  if (filename.match(/\.mp4$/i)) {
    fileType = 'video'
  } else if (filename.match(/\.(mp3)$/i)) {
    fileType = 'audio'
  } else if (filename.match(/\.(jpe?g|png|gif)/i)) {
    fileType = 'image'
  }
  return fileType
}
export function humanSize(size, ignorBit) {
  if (!size) return ''
  if (size < 1024) {
    if (ignorBit) {
      return '0KB'
    }
    return size + 'B'
  }
  size = size / 1024
  if (size > 1024) {
    size = (size / 1024).toFixed(1) + 'MB'
  } else {
    size = size.toFixed(1) + 'KB'
  }
  return size
}

export function saveBlobFile(blob, filename) {
  if (navigator.msSaveBlob) {
    // For ie and Edge
    return navigator.msSaveBlob(blob, filename)
  } else {
    let link = document.createElement('a')
    link.style = 'display: none'
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    document.body.appendChild(link)
    link.dispatchEvent(
      new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
    )
    link.remove()
    window.URL.revokeObjectURL(link.href)
  }
}
export function downloadFile(url, filename = '') {
  let link = document.createElement('a')
  link.style = 'display: none'
  link.href = url
  link.download = filename
  document.body.appendChild(link)
  link.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
  )
  link.remove()
}

export function getLabel(value, options = []) {
  if (typeof value !== 'string' && typeof value !== 'number') return ''
  for (let item of options) {
    if (item.value === value) {
      return item.getLabel ? item.getLabel() : item.label
    }
  }
  return value
}

export function getGuid() {
  // 替换最后四个字符为MBL2,作为后端确认设备来源
  let str =
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    '_WEB'
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return str.toUpperCase()
}
export function getReqId() {
  let s1 = Math.floor((1 + Math.random()) * 0x100000000).toString(16)
  let s2 = Math.floor((1 + Math.random()) * 0x100000000).toString(16)
  return s1 + s2 + '-' + Date.now()
}

export function handleNRCData(urls) {
  urls = urls || ''
  const urlArr = urls.split(';')
  let nrcFront = ''
  let nrcBack = ''
  let personal = ''
  // FRONT@xxx;BACK@xxxx;
  if (urls.indexOf('@') !== -1) {
    for (let item of urlArr) {
      const [type, url] = item.split('@')
      if (type === 'FRONT') {
        nrcFront = url
      } else if (type === 'BACK') {
        nrcBack = url
      } else if (type === 'PERSON') {
        personal = url
      }
    }
  } else {
    nrcFront = urlArr[0]
    nrcBack = urlArr[1]
    personal = urlArr[2]
  }
  if (nrcFront === 'null') {
    nrcFront = ''
  }
  if (nrcBack === 'null') {
    nrcBack = ''
  }
  if (personal === 'null') {
    personal = ''
  }
  return {
    nrcFront,
    nrcBack,
    personal
  }
}

/*
 * el-cascader递归获取父级id
 * @param  list 数据列表
 * @param  id 后端返回的id
 * @param  children 子类的key
 * @param  keyStr  idkey  默认 ‘id’
 **/
export function getParentsById(list, id, children, keyStr) {
  let key = keyStr
  if (!key) {
    key = 'id'
  }
  for (let i in list) {
    if (list[i][key] == id) {
      return [list[i][key]]
    }
    if (list[i][children]) {
      let node = getParentsById(list[i][children], id, children)
      if (node !== undefined) {
        node.unshift(list[i][key])
        return node
      }
    }
  }
}

export function printClick() {
  let el = document.getElementById('print_content')
  let doc = document
  let body = doc.body || doc.getElementsByTagName('body')[0]
  let printId = 'print-' + Date.now()
  let content = doc.createElement('div')
  content.id = printId
  let style = doc.createElement('style')
  style.innerHTML =
    'body>#' +
    printId +
    '{display:none}@media print{body>:not(#' +
    printId +
    '){display:none !important}body>#' +
    printId +
    '{display:block;padding-top:1px}}'
  content.innerHTML = el.outerHTML
  body.appendChild(style)
  body.appendChild(content)
  setTimeout(() => {
    window.print()
    body.removeChild(content)
    body.removeChild(style)
  }, 20)
}

export function getTimeDisplayText(tp, value) {
  // Day: '1', Month: '2', Quarter: '3', Year: '4'
  if (tp === '1') {
    return cbpDate(value)
  }
  if (tp === '2') {
    let m = value.substring(4, 6)
    if (m.length === 1) {
      m = '0' + m
    }
    return `${value.substring(0, 4)}-${m}`
  }
  if (tp === '3') {
    const q = value.substring(4, 5)
    const quarterText = {
      1: i18n.t('Quarter1'),
      2: i18n.t('Quarter2'),
      3: i18n.t('Quarter3'),
      4: i18n.t('Quarter4')
    }
    return `${value.substring(0, 4)} ${quarterText[q]}`
  }
  if (tp === '4') {
    return value.substring(0, 4) + ' ' + i18n.t('SCRNITM#yr')
  }
}
