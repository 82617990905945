<i18n src="@/i18n/template/projects/teller/components/TellerStaffSelect.json"></i18n>
<template>
  <el-select
    :value="value"
    @input="$emit('input', $event)"
    :filterable="true"
    :disabled="disabled"
    :clearable="clearable"
    :loading="loading"
    @change="$emit('change', $event)"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>
<script>
import api from '@/api/index'
import i18n from '@/i18n'
export async function getStaffOptions() {
  let res = await api.requestCBB('SSF2000001', {
    StaffDeptInfoIn: {
      instCd: '205',
      actorNm: '',
      deptId: ''
    }
  })

  if (res.StaffInfoListOut) {
    let list = res.StaffInfoListOut.tbl || []
    return list.map(item => {
      return {
        value: item.staffId,
        label: `${item.actorNm}  (${i18n.t('StaffID')}: ${item.staffId})`
      }
    })
  }
  return []
}

export default {
  props: ['value', 'clearable', 'disabled'],
  data() {
    return {
      loading: true,
      options: []
    }
  },
  async created() {
    this.loading = true
    let res = await getStaffOptions()
    this.loading = false
    this.options = res
  }
}
</script>
