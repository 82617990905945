<template>
  <el-input
    class="mobile-input"
    :class="{
      'mobile-input-mm': nation == '95'
    }"
    :value="mobileNbr"
    @input="inputChange"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    :disabled="disabled"
    maxlength="20"
  >
    <template slot="prepend">
      <el-select
        :value="nation"
        @input="onNationChange"
        :disabled="nationDisabled"
      >
        <el-option label="+95" value="95"></el-option>
        <el-option label="+86" value="86"></el-option>
        <el-option label="+886" value="886"></el-option>
        <el-option label="+852" value="852"></el-option>
        <el-option label="+66" value="66"></el-option>
        <el-option label="+65" value="65"></el-option>
        <el-option label="+91" value="91"></el-option>
        <el-option label="+1" value="1"></el-option>
        <el-option label="+63" value="63"></el-option>
        <el-option label="+60" value="60"></el-option>
        <el-option label="+61" value="61"></el-option>
        <el-option label="+81" value="81"></el-option>
        <el-option label="+82" value="82"></el-option>
        <el-option label="+44" value="44"></el-option>
      </el-select>
      <span class="mobile-input-mm--09">09</span>
    </template>
  </el-input>
</template>

<script>
export default {
  props: {
    value: String,
    nation: String,
    placeholder: String,
    disabled: Boolean,
    nationDisabled: Boolean
  },
  computed: {
    mobileNbr() {
      if (this.nation === '95') {
        if (this.value.substring(0, 2) === '09') {
          return this.value.substring(2)
        }
        return this.value
      }
      return this.value
    }
  },
  methods: {
    onNationChange(val) {
      this.$emit('update:nation', val)
      if (val === '95' && this.value) {
        this.$emit('input', '09' + this.value)
      }
    },
    inputChange(val) {
      if (val === '') {
        this.$emit('input', val)
      } else if (val.match(/^\d+$/)) {
        if (this.nation == '95') {
          val = '09' + val
        }
        this.$emit('input', val)
      }
    }
  }
}
</script>
<style lang="scss">
$selecte-width: 80px;
.mobile-input {
  .el-input-group__prepend {
    background: #fff;
    .el-select {
      width: $selecte-width;
    }
  }
  &.mobile-input-mm {
    .mobile-input-mm--09 {
      display: block;
      position: absolute;
      left: $selecte-width + 8px;
      top: 50%;
      transform: translateY(-50%);
      color: #888;
      font-size: 13px;
    }
    > .el-input__inner {
      padding-left: 26px;
    }
  }
  .mobile-input-mm--09 {
    display: none;
  }
}
.mobile-input.is-disabled .el-input-group__prepend {
  background: #f5f7fa;
}
</style>
