var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-tabs"},[_c('div',{ref:"navWrap",staticClass:"my-tabs-nav-wrap"},[_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,ref:"navItem",refInFor:true,class:[
        'my-tabs-tab',
        {
          active: _vm.activeKey === item.name,
          hide: item.hide
        }
      ],on:{"click":function($event){return _vm.handleTabChange(item)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),(_vm.showPopoverButton)?_c('el-popover',{attrs:{"placement":"bottom","width":"500","trigger":"hover"},model:{value:(_vm.popoverVisible),callback:function ($$v) {_vm.popoverVisible=$$v},expression:"popoverVisible"}},[_c('div',{staticClass:"my-popover-tabs"},[_vm._l((_vm.navList),function(item,index){return [(item.hide)?_c('div',{key:index,class:[
              'my-popover-tabs-item',
              { active: _vm.activeKey === item.name }
            ],on:{"click":function($event){return _vm.handleMoreChange(item)}}},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e()]})],2),_c('span',{staticClass:"popover-tabs-reference",attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"el-icon-s-grid"})])]):_vm._e()],2),_c('div',{staticClass:"my-tabs-content"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }