<template>
  <el-date-picker
    :value="value"
    @input="$emit('input', $event)"
    :type="type"
    :placeholder="placeholder"
    :value-format="valueFormat"
    :format="format"
    :picker-options="options"
    :default-time="defaultTime"
    :disabled="disabled"
    :clearable="clearable"
  >
  </el-date-picker>
</template>

<script>
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { formatTimeTemplate, formatDateTemplate } from '@/util'
dayjs.extend(customParseFormat)
export default {
  props: {
    type: String,
    value: String,
    endTime: String,
    startTime: String,
    valueFormat: String,
    format: String,
    placeholder: String,
    defaultTime: String,
    disabled: Boolean,
    clearable: Boolean,
    intervalDays: Number, // 相隔天数
    minDate: String,
    maxDate: String
  },
  data() {
    return {
      options: {
        disabledDate: this.disabledDate
      }
    }
  },
  methods: {
    disabledDate(time) {
      const t = time.getTime() // Moved this outside for reuse
      let sT = this.startTime ? this.parseDate(this.startTime) : null
      let eT = this.endTime ? this.parseDate(this.endTime) : null

      // 处理 startTime
      if (sT !== null) {
        if (this.intervalDays) {
          return (
            t < sT || t > sT + (this.intervalDays - 1) * 1000 * 60 * 60 * 24
          )
        }
        return t < sT
      }
      // 处理 endTime
      if (eT !== null) {
        if (this.intervalDays) {
          return t > eT || t < eT - this.intervalDays * 1000 * 60 * 60 * 24
        }
        return t > eT
      }

      // 添加 minDate 和 maxDate 的检查
      if (this.minDate) {
        const minDateTimestamp = this.parseDate(this.minDate)
        if (minDateTimestamp !== null) {
          return t < minDateTimestamp
        }
      }
      if (this.maxDate) {
        const maxDateTimestamp = this.parseDate(this.maxDate)
        if (maxDateTimestamp !== null) {
          return t > maxDateTimestamp
        }
      }
      return false // 确保在没有条件满足时返回一个默认值
    },
    parseDate(dateString) {
      // 使用 dayjs 解析日期字符串
      const date = dayjs(
        dateString,
        [formatDateTemplate, formatTimeTemplate],
        true
      )
      return date.isValid() ? date.valueOf() : null // 返回时间戳
    }
  }
}
</script>
