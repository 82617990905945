<template>
  <div v-if="!loaded"></div>
  <div v-else v-show="show">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'MyTabPane',
  props: {
    name: {
      type: String
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loaded: false,
      show: false
    }
  },
  mounted() {
    this.$parent.initTabs()
  },
  watch: {
    label() {
      this.$parent.initTabs()
    },
    name() {
      this.$parent.initTabs()
    },
    show(val) {
      if (val) {
        this.loaded = true
      }
    }
  }
}
</script>
