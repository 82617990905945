<template>
  <div class="common-row">
    <div class="common-row-header">
      <div class="left-view">
        <div class="expand-icon" v-if="expandable" @click="toggleExpand">
          <i class="el-icon-remove" v-if="isExpand"></i>
          <i class="el-icon-circle-plus" v-else></i>
          <span :style="titleStyle">{{ title }}</span>
          <slot name="title"></slot>
        </div>
        <span v-else :style="titleStyle">
          {{ title }}
          <slot name="title"></slot>
        </span>
        <slot name="leftView"></slot>
      </div>
      <span>
        <slot name="button"></slot>
      </span>
    </div>
    <transition name="common-row-content">
      <div class="common-row-content" v-show="isExpand">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'common-row',
  props: {
    title: {
      type: String
    },
    titleStyle: {
      type: Object
    },
    expandable: {
      type: Boolean,
      default: false
    },
    expand: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isExpand: this.expand
    }
  },
  watch: {
    expand(newVal) {
      this.isExpand = newVal
    }
  },
  methods: {
    toggleExpand() {
      this.isExpand = !this.isExpand
      this.$emit('update:expand', this.isExpand)
    }
  }
}
</script>
<style lang="scss">
.common-row {
  &-header {
    display: flex;
    flex-direction: row;
    background-color: #f2f2f2;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #324157;
    .left-view {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      color: #101010;
    }
    .expand-icon {
      cursor: pointer;
      i {
        margin-right: 4px;
        color: #444;
      }
    }
  }
  &-content {
    margin-top: 8px;
    overflow: hidden;
  }
  .common-row-content-enter-active,
  .common-row-content-leave-active {
    transition: all 0.3s ease;
    max-height: 9000px;
  }

  .common-row-content-enter,
  .common-row-content-leave-to {
    max-height: 0;
    opacity: 0;
  }
}
</style>
