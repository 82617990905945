import '@/i18n/template/util/filters.js'
import * as util from './util'
import dayjs from 'dayjs'
import { NeverExpiredTime } from '@/constant'
import i18n from '@/i18n'

/**
 * 按日历格式显示时间： "Today 12:00"  "Last week 14:12" "7/10/2011"
    dayjs().calendar(null, {
      sameDay: '[Today at] h:mm A', // The same day ( Today at 2:30 AM )
      nextDay: '[Tomorrow]', // The next day ( Tomorrow at 2:30 AM )
      nextWeek: 'dddd', // The next week ( Sunday at 2:30 AM )
      lastDay: '[Yesterday]', // The day before ( Yesterday at 2:30 AM )
      lastWeek: '[Last] dddd', // Last week ( Last Monday at 2:30 AM )
      sameElse: 'DD/MM/YYYY' // Everything else ( 7/10/2011 )
    })
 * */
export function calender(time) {
  return dayjs(time).calendar(dayjs(), {
    sameDay: '[Today at] HH:mm', // The same day ( Today at 2:30 AM )
    nextDay: '[Tomorrow]', // The next day ( Tomorrow at 2:30 AM )
    nextWeek: 'ddd', // The next week ( Sunday at 2:30 AM )
    lastDay: '[Yesterday]', // The day before ( Yesterday at 2:30 AM )
    lastWeek: '[Last] ddd', // Last week ( Last Monday at 2:30 AM )
    sameElse: 'DD/MM/YYYY' // Everything else ( 7/10/2011 )
  })
}

export function username(data = {}) {
  let name = data.frdNickNm || ''
  let extras = data.ext || {}
  name = name.trim() || data.nickname || extras.realName || data.username || ''
  return name
}

/**
 * 格式化显示金额 加千分位逗号 1000000 => 1,000,000
 * @param {*} num 数字
 */
export function money(num) {
  if (num === null || num === undefined) return ''
  if (typeof num !== 'number' && typeof num !== 'string') return ''
  num = num + ''
  if (num === '') return ''
  if (num === 'NaN') return ''
  var regx = /(-?\d+)(\d{3})/
  var bExists = num.indexOf('.', 0)
  var strArr = num.split('.')
  while (regx.test(strArr[0])) {
    strArr[0] = strArr[0].replace(regx, '$1,$2')
  }
  if (bExists > -1) return strArr[0] + '.' + strArr[1]
  else return strArr[0]
}
// 将金额转换为 k  m
export function shortMoney(val) {
  if (val === null || val === undefined) return '0'
  if (typeof val !== 'number' && typeof val !== 'string') return ''
  let negative = val < 0
  val = Math.abs(val)
  let sizes
  if (val < 1000) {
    sizes = val.toString()
  } else if (val >= 1000 && val < 1000000) {
    sizes = (val / 1000).toFixed(2) + 'K'
  } else if (val >= 1000000 && val < 1000000000) {
    sizes = (val / 1000000).toFixed(2) + 'M'
  } else if (val >= 1000000000) {
    sizes = (val / 1000000000).toFixed(2) + 'B'
  }
  return negative ? '-' + sizes : sizes
}

// 格式化显示金额 保留2位小数
export function money2(num) {
  num = Number(num).toFixed(2)
  return money(num)
}
// 格式化显示金额 保留4位小数
export function money4(num) {
  num = Number(num).toFixed(4)
  return money(num)
}
// 保留2位小数
export function fixed2(num) {
  if (num == null || num === '') return ''
  if (Number.isNaN(num)) {
    return num
  }
  return Number(num).toFixed(2)
}
// 保留4位小数
export function fixed4(num) {
  if (num == null || num === '') return ''
  if (Number.isNaN(num)) {
    return num
  }
  return Number(num).toFixed(4)
}
// 格式化日期显示
export let cbpDate = util.cbpDate
export let old_cbpDate = util.old_cbpDate

export let cbpTime = util.cbpTime

// 非cbp的日期显示
export function cbpMonth(t = '') {
  if (!t) return ''
  return `${t.substring(0, 4)}/${t.substring(4, 6)}`
}

// 非cbp的日期显示
export function date(t) {
  if (!t) return ''
  if (typeof t === 'string' && t.replace(/[^\d]/g, '') === NeverExpiredTime) {
    return i18n.t('NeverExpire')
  }
  return dayjs(t).formatDate()
}
// 非cbp的time显示
export function time(t, separate = '/') {
  if (!t) return '' // 检查输入是否为空
  if (typeof t === 'string' && t.replace(/[^\d]/g, '') === NeverExpiredTime) {
    return i18n.t('NeverExpire')
  }
  // 尝试解析日期
  const parsedDate = dayjs(t)
  // 检查解析后的日期是否有效
  if (!parsedDate.isValid()) {
    return '' // 如果无效，返回空字符串
  }
  return parsedDate.format(`DD${separate}MM${separate}YYYY HH:mm:ss`) // 使用指定格式返回本地时间
  // 获取当前时区的偏移（分钟）
  const localOffset = new Date().getTimezoneOffset() // 本地时区偏移（分钟）
  const myanmarOffset = -390 // 缅甸时区偏移（UTC+6:30），转换为分钟
  // 计算时间差
  const offsetDifference = localOffset - myanmarOffset // 计算本地时区与缅甸时区的差值（分钟）
  // 将缅甸时间转换为本地时间
  const localTime = parsedDate.add(offsetDifference, 'minute')
  // 返回格式化后的本地时间
  return localTime.format(`DD${separate}MM${separate}YYYY HH:mm:ss`) // 使用指定格式返回本地时间
}

export function old_time(t, separate = '/') {
  if (!t) return ''
  if (typeof t === 'string' && t.replace(/[^\d]/g, '') === NeverExpiredTime) {
    return i18n.t('NeverExpire')
  }
  if (separate === '-') {
    return dayjs(t).format('YYYY-MM-DD HH:mm:ss')
  }
  return dayjs(t).format()
}

export function reSetmoney(t) {
  if (!t) {
    return 0
  }
  return Number(parseFloat(t.replace(/[^\d.-]/g, '')))
}

export function getAge(val) {
  if (!val) return ''
  val = cbpDate(val)
  let currentYear = new Date().getFullYear()
  let calculationYear = new Date(val).getFullYear()
  const wholeTime = currentYear + val.substring(4)
  const calculationAge = currentYear - calculationYear
  //判断是否过了生日
  if (new Date().getTime() > new Date(wholeTime).getTime()) {
    return calculationAge
  } else {
    return calculationAge - 1
  }
}

export function formatDuration(second) {
  //秒
  if (!second) {
    return '0'
  }
  var days = Math.floor(second / (60 * 60 * 24))
  var hours = Math.floor((second % (60 * 60 * 24)) / (60 * 60))
  var minutes = Math.floor((second % (60 * 60)) / 60)
  var seconds = Math.floor(second % 60)
  return (
    (days ? days + 'd ' : '') +
    (hours ? hours + 'h ' : '') +
    (minutes ? minutes + 'm ' : '') +
    seconds +
    's'
  )
}

export function formatMillisecond(millisecond) {
  // 毫秒
  if (!millisecond) {
    return '0.00' // 返回 '0.00' 以保留两位小数
  }
  var seconds = (millisecond / 1000).toFixed(2) // 将毫秒转换为秒并保留两位小数
  return seconds + 's' // 返回格式化的秒数
}
