import i18n from '@/i18n'
i18n.mergeLocaleMessage('en', {
  "merchant.unpaid": "Unpaid",
  "Paid": "Paid",
  "PAYFAILED": "PAY FAILED",
  "Paying": "Paying",
  "REFUNDING": "REFUNDING",
  "REFUNDED": "REFUNDED",
  "REFUNDEDREJECT": "REFUND REJECTED",
  "chat.Close": "Close",
  "OpenAccount": "Open Account",
  "AddMember": "Add Member",
  "RemoveMember": "Remove Member",
  "Transfer": "Transfer",
  "Payment": "Payment",
  "BankTransfer": "Bank Transfer",
  "chat.requestMoney": "Trusty request money",
  "CashOut": "Cash Out",
  "RedPacket.title": "Send Red Packet",
  "FxExchangeBuy": "Fx Exchange Buy",
  "accountStr.fixed": "Fixed",
  "Repayment": "Repayment",
  "TrustyCoinsExchangeBuy": "Trusty Coins Exchange Buy",
  "GeneralMerchants": "General merchants",
  "CashCounter": "Cash Counter",
  "agency_management": "Agency Payment Management",
  "Restaurant": "Restaurant"
})
i18n.mergeLocaleMessage('zh', {
  "merchant.unpaid": "未支付",
  "Paid": "已支付",
  "PAYFAILED": "支付失败",
  "Paying": "支付中",
  "REFUNDING": "退款中",
  "REFUNDED": "已退款",
  "REFUNDEDREJECT": "拒绝退款",
  "chat.Close": "关闭",
  "OpenAccount": "开户",
  "AddMember": "添加成员",
  "RemoveMember": "移除成员",
  "Transfer": "转账",
  "Payment": "支付",
  "BankTransfer": "银行转账",
  "chat.requestMoney": "Trusty收钱",
  "CashOut": "提现",
  "RedPacket.title": "发红包",
  "FxExchangeBuy": "外汇买入",
  "accountStr.fixed": "定期",
  "Repayment": "还款",
  "TrustyCoinsExchangeBuy": "Trusty币兑入",
  "GeneralMerchants": "一般商户",
  "CashCounter": "现金柜台",
  "agency_management": "工资代发",
  "Restaurant": "餐饮"
})
i18n.mergeLocaleMessage('mm', {
  "merchant.unpaid": "မရှင်းရသေး။",
  "Paid": "ပေးချေပြီး",
  "PAYFAILED": "ငွေပေးချေမှု မအောင်မြင်ပါ။",
  "Paying": "ပေးဆောင်ခြင်း",
  "REFUNDING": "ငွေပြန်အမ်းနေစဥ်",
  "REFUNDED": "ငွေပြန်အမ်းသည်။",
  "REFUNDEDREJECT": "ငွေပြန်အမ်းရန် မအောင်မြင်ပါ။",
  "chat.Close": "ပိတ်ရန်",
  "OpenAccount": "ဖွင့်ထားသောအကောင့်",
  "AddMember": "အဖွဲ့ဝင်ထည့်မည်",
  "RemoveMember": "အဖွဲ့ဝင်ကို ဖယ်ရှားမည်",
  "Transfer": "ငွေလွှဲ",
  "Payment": "ပေးချေမှု",
  "BankTransfer": "ဘဏ်ငွေလွှဲ",
  "chat.requestMoney": "Trusty ငွေတောင်းခံ",
  "CashOut": "ငွေထုတ်",
  "RedPacket.title": "အန်ပေါင်ပေးရန်",
  "FxExchangeBuy": "Fx Exchange ကိုဝယ်ပါ။",
  "accountStr.fixed": "စာရင်းသေ",
  "Repayment": "ပြန်ပေးဆပ်ခြင်း",
  "TrustyCoinsExchangeBuy": "Trusty အကြွေစေ့လဲလှယ် ဝယ်ယူခြင်း",
  "GeneralMerchants": "General merchants",
  "CashCounter": "ငွေကောင်တာ",
  "agency_management": "အေဂျင်စီငွေပေးချေမှုစီမံခန့်ခွဲမှု",
  "Restaurant": "Restaurant"
})
  