import { render, staticRenderFns } from "./TellerStaffSelect.vue?vue&type=template&id=1bcfaddc&"
import script from "./TellerStaffSelect.vue?vue&type=script&lang=js&"
export * from "./TellerStaffSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/template/projects/teller/components/TellerStaffSelect.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2FUsers%2Fping%2F.jenkins%2Fworkspace%2FJL_UAT_MPORTAL%2Fsrc%2Fprojects%2Fteller%2Fcomponents%2FTellerStaffSelect.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports